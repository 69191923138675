import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import Mixin from "./mixins/comm";
Vue.mixin(Mixin);

import "./styles/reset.css";
import "./styles/comm.css";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  // mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");

router.afterEach(() => {
  window.scrollTo(0, 0);
});

// 加入百度统计
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }
  next();
});
