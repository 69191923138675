<template>
  <div class="footer-wrap">
    <!-- contact -->
    <div class="footer-contact">
      <div class="contact w1200">
        <h3>联系客服 ， 即可免费体验飞兔智能优化防护</h3>
        <p>告别传统防御方式 ， 让您的业务更快、更安全、更省心</p>
        <div class="btns">
          <div class="c-btn" @click="routerJump('contact')">即刻体验</div>
          <div class="c-btn orange" @click="routerJump('price')">查看价格</div>
        </div>
      </div>
    </div>
    <!-- contact -->
    <!-- footer -->
    <div class="footer">
      <div class="footer-content">
        <div class="logo fl" @click="routerJump()"></div>
        <div class="right fl">
          <dl>
            <dt>产品与服务</dt>
            <dd @click="routerJump('optimization')">智能优化</dd>
            <dd @click="routerJump('acceleration')">智能加速</dd>
            <dd @click="routerJump('protection')">智能保护</dd>
          </dl>
          <dl>
            <dt>解决方案</dt>
            <dd @click="routerJump('contact')">网站加速</dd>
            <dd @click="routerJump('contact')">域名加速</dd>
            <dd @click="routerJump('contact')">DDos防御</dd>
          </dl>
          <dl>
            <dt>公司相关</dt>
            <dd>关于我们</dd>
            <dd @click="routerJump('contact')">联系我们</dd>
            <dd>常见问题</dd>
            <dd>帮助中心</dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="copyright">Copyright ©2020 Feeto. All rights reserved</div>
  </div>
</template>

<script>
export default {
  name: "NavPage",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  methods: {
    changeLanguage() {},
  },
};
</script>

<style scoped lang="less">
.footer-wrap {
  .footer-contact {
    min-width: 1200px;
    height: 432px;
    text-align: center;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff) 0 100%
        repeat-x,
      url(../assets/home_contact_pic_line1@2x.webp) 0 0 no-repeat,
      url(../assets/home_contact_pic_line2@2x.webp) 100% 100% no-repeat,
      linear-gradient(180deg, rgba(0, 111, 255, 0.4), rgba(243, 247, 255, 0.4))
        0 0 repeat-x;
    background-size: 100% 94px, 320px 238px, 1000px 260px, 100% 100%;
    .contact {
      h3 {
        font-size: 40px;
        font-weight: 500;
        color: #001734;
        padding-top: 100px;
      }
      p {
        margin-top: 30px;
        font-size: 24px;
        font-weight: 400;
        color: #3a4a6e;
      }
      .btns {
        margin: 64px auto 0;
        width: 330px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .footer {
    padding: 50px 0;
    overflow: hidden;
    border-top: 1px solid #e4e9f0;
    .footer-content {
      width: 800px;
      margin: 0 auto;
    }
    .logo {
      width: 200px;
      height: 80px;
      float: left;
      background: url(@/assets/logo-blue.jpg) no-repeat left top;
      background-size: auto 100%;
    }
    .right {
      dl {
        width: 200px;
        float: left;
        dt {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 700;
          color: #001734;
          padding-bottom: 8px;
        }
        dd {
          line-height: 34px;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            color: #ff905d;
          }
        }
      }
    }
  }
  .copyright {
    text-align: center;
    font-size: 16px;
    padding: 30px 0;
    font-weight: 500;
    color: #3a4a6e;
  }
}
</style>
